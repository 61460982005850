<template>
  <div>
    <span v-if="contractIsLoaded">
      <aTooltip
        v-if="
          checkinIsComing(this.sale, theContract) &&
          theContract.meta.manual_anti_fraud !== 'Concluída'
        "
        placement="top"
      >
        <template #title>
          <div class="f13s">
            O check-in deste contrato <br />
            está próximo:
            <b> {{ formatDate(theContract.first_checkin) }} </b>
          </div>
        </template>

        <img
          src="@/assets/images/dashboard/contracts/fraud-red.png"
          width="18"
          alt="img"
        />
      </aTooltip>

      <img
        v-if="theContract.meta.manual_anti_fraud === 'Concluída'"
        src="@/assets/images/dashboard/contracts/fraud-green.png"
        width="18"
        alt="img"
      />
    </span>

    <span class="ml-10" v-if="$root.isAdmin()">
      <a-tooltip placement="top" title="Editar dados">
        <a class="edit grayscale relative top5" @click="editSale(sale)">
          <a-icon type="edit-svg" />
        </a>
      </a-tooltip>
    </span>

    <a-tooltip v-if="contractIsLoaded" placement="top" title="Status à receber">
      <img
        v-if="!isAllIncomingsReceived"
        class="c-pointer ml-10"
        src="@/assets/images/dashboard/sales/incomings-red.png"
        width="20"
        alt="img"
      />

      <img
        v-if="isAllIncomingsReceived"
        class="c-pointer ml-10"
        src="@/assets/images/dashboard/sales/incomings-green.png"
        alt="img"
        width="20"
      />
    </a-tooltip>

    <span v-if="!contractIsLoaded" class="ml-10">
      <a-icon type="loading" />
    </span>

    <a-tooltip v-if="contractIsLoaded" placement="top" title="Status à pagar">
      <img
        v-if="!isAllPaid"
        class="c-pointer ml-10"
        src="@/assets/images/dashboard/sales/topay-red.png"
        width="20"
        alt="img"
      />

      <img
        v-if="isAllPaid"
        class="c-pointer ml-10"
        src="@/assets/images/dashboard/sales/topay-green.png"
        width="20"
        alt="img"
      />
    </a-tooltip>

    <span v-if="!contractIsLoaded" class="ml-10">
      <a-icon type="loading" />
    </span>

    <a-tooltip placement="top" title="Imprimir venda">
      <a-icon class="ml-10 print" @click="printSale(sale)" type="printer" />
    </a-tooltip>

    <span
      v-if="$root.isAdmin()"
      class="actions-outer"
      style="position: relative"
    >
      <a-tooltip placement="top" title="Ver a venda">
        <a class="edit ml-10" @click="viewSale(sale.id)">
          <img
            v-if="sale.status == 'Revisão' || sale.status == 'Cancelado'"
            src="@/assets/images/dashboard/sales/pending-sale.png"
            alt="img"
          />
          <img
            v-else
            src="@/assets/images/dashboard/sales/concluded-sale.png"
            alt="img"
          />
        </a>
      </a-tooltip>
    </span>

    <span
      v-if="activeTab == 'Revisão' && !$root.isAdmin()"
      class="actions-outer"
      style="position: relative"
    >
      <a-tooltip placement="top" title="Ver a venda">
        <a class="edit ml-10" @click="viewSale(sale.id)">
          <img
            v-if="sale.status == 'Revisão' || sale.status == 'Cancelado'"
            src="@/assets/images/dashboard/sales/pending-sale.png"
            alt="img"
          />
          <img
            v-else
            src="@/assets/images/dashboard/sales/concluded-sale.png"
            alt="img"
          />
        </a>
      </a-tooltip>
    </span>
  </div>
</template>

<script>
import {
  format,
  parse,
  parseISO,
  isAfter,
  isBefore,
  addDays,
  isValid,
} from "date-fns";

export default {
  name: "SalesTableActions",
  props: {
    sale: Object,
    activeTab: String,
  },
  data() {
    return {
      theContract: {},
      contractIsLoaded: false,
      isAllPaid: false,
      isAllIncomingsReceived: false,
    };
  },
  mounted() {
    this.$http
      .post(`/contract-v2/details?id=${this.sale.contract_id}`)
      .then(({ data }) => {
        this.theContract = data;
        this.statusToPay(data);
        this.incomingsStatus(data);
        this.contractIsLoaded = true;
      });
  },
  methods: {
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd", new Date());
      return format(theDate, "dd/MM/yyyy");
    },
    checkinIsComing(sale, contract) {
      let flag = false;
      if (
        contract.status === "concluded" &&
        this.isDateWithinNextDays(
          contract.first_checkin,
          sale.company_branch.days_to_checkin_alert
        )
      )
        flag = true;
      return flag;
    },
    isDateWithinNextDays(dateStr, days) {
      // Check if dateStr is provided
      if (!dateStr) return false;

      // Parse the date string
      const targetDate = parseISO(dateStr);

      // Check if the parsed date is valid
      if (!isValid(targetDate)) return false;

      // Get today's date
      const today = new Date();

      // Get the date 90 days from today
      const dateIn90Days = addDays(today, days);

      // Check if the target date is between today and the date 90 days from today
      return isAfter(targetDate, today) && isBefore(targetDate, dateIn90Days);
    },
    editSale(sale) {
      this.$emit("editSale", sale);
    },
    printSale(sale) {
      this.$emit("printSale", sale);
    },
    viewSale(id) {
      this.$emit("viewSale", id);
    },
    incomingsStatus(contract) {
      const paymentMethods = JSON.parse(contract.payment_methods);
      let customPaymentIsReceived = false;
      let crediCardsIsReceived = false;
      let bankTransferIsReceived = false;
      let paymentCardIsReceived = false;
      let billingTicketIsReceived = false;
      let paymentLinkIsReceived = false;

      paymentMethods.forEach((payment) => {
        if (payment === "credit-card") {
          const creditCardRows = JSON.parse(contract.meta.credit_card_rows);
          const creditCardsReceivedArr = [];

          creditCardRows.forEach((cCard) => {
            if (
              contract.meta[
                `payment_methods_credit_card_audit_status_${cCard.id}`
              ] === "Realizado"
            )
              creditCardsReceivedArr.push(cCard);
          });

          if (creditCardRows.length === creditCardsReceivedArr.length)
            crediCardsIsReceived = true;
        }

        if (payment === "payment-link") {
          const paymentLinkRows = JSON.parse(contract.meta.payment_link_rows);
          const paymentLinkReceivedArr = [];

          paymentLinkRows.forEach((link) => {
            if (
              contract.meta[
                `payment_methods_payment_link_audit_status_${link.id}`
              ] === "Realizado"
            )
              paymentLinkReceivedArr.push(link);
          });

          if (paymentLinkReceivedArr.length === paymentLinkRows.length)
            paymentLinkIsReceived = true;
        }

        if (payment === "bank-transfer") {
          const bankTransferRows = contract.meta.bank_transfer_rows
            ? JSON.parse(contract.meta.bank_transfer_rows)
            : [];
          const bankTransfersReceivedArr = [];

          if (bankTransferRows.length) {
            bankTransferRows.forEach((bankTransfer) => {
              if (
                contract.meta[
                  `payment_methods_bank_transfer_audit_status_${bankTransfer.id}`
                ] === "Realizado"
              )
                bankTransfersReceivedArr.push(bankTransfer);
            });

            if (bankTransferRows.length === bankTransfersReceivedArr.length)
              bankTransferIsReceived = true;
          } else {
            if (
              contract.meta[`payment_methods_bank_transfer_audit_status`] ===
              "Realizado"
            )
              bankTransferIsReceived = true;
          }
        }

        if (payment === "custom-payment") {
          const customPaymentRows = contract.meta.custom_payment_rows
            ? JSON.parse(contract.meta.custom_payment_rows)
            : [];
          const customPaymentReceivedArr = [];

          if (customPaymentRows.length) {
            customPaymentRows.forEach((custom) => {
              if (
                contract.meta[
                  `payment_methods_custom_payment_audit_status_${custom.id}`
                ] === "Realizado"
              )
                customPaymentReceivedArr.push(custom);
            });

            if (customPaymentRows.length === customPaymentReceivedArr.length)
              customPaymentIsReceived = true;
          } else {
            if (
              contract.meta[`payment_methods_custom_payment_audit_status`] ===
              "Realizado"
            )
              customPaymentIsReceived = true;
          }
        }

        if (payment === "payment-card") {
          if (
            contract.meta[`payment_methods_payment_card_audit_status`] ===
            "Realizado"
          )
            paymentCardIsReceived = true;
        }

        if (payment === "billing-ticket") {
          if (
            contract.meta[`payment_methods_billing_ticket_audit_status`] ===
            "Realizado"
          )
            billingTicketIsReceived = true;
        }
      });

      if (!paymentMethods.includes("credit-card")) crediCardsIsReceived = true;
      if (!paymentMethods.includes("payment-link"))
        paymentLinkIsReceived = true;
      if (!paymentMethods.includes("bank-transfer"))
        bankTransferIsReceived = true;
      if (!paymentMethods.includes("billing-ticket"))
        billingTicketIsReceived = true;
      if (!paymentMethods.includes("payment-card"))
        paymentCardIsReceived = true;
      if (!paymentMethods.includes("custom-payment"))
        customPaymentIsReceived = true;

      this.isAllIncomingsReceived =
        customPaymentIsReceived &&
        crediCardsIsReceived &&
        bankTransferIsReceived &&
        paymentCardIsReceived &&
        billingTicketIsReceived &&
        paymentLinkIsReceived;
    },
    statusToPay(contract) {
      const contractedServices = JSON.parse(contract.contracted_services);
      const productType = contract.package_type;
      const arrPaidHotels = [];
      let allHotelsIsPaid = false;
      const arrPaidFlights = [];
      let allFlightsIsPaid = false;
      const arrPaidServices = [];
      let allServicesIsPaid = false;
      let packageIsPaid = false;

      if (productType === "Pacote Completo Terceiros") {
        allHotelsIsPaid = true;
        allFlightsIsPaid = true;
        allServicesIsPaid = true;
        if (
          contract.meta[`contract_finances_package_audit_status`] ===
          "Realizado"
        )
          packageIsPaid = true;
      } else {
        contractedServices.forEach((product) => {
          if (product === "hotel") {
            const hotelRows = JSON.parse(contract.meta.hotel_rows);
            hotelRows.forEach((hotel) => {
              if (
                contract.meta[
                  `contract_finances_hotel_${hotel.id}_audit_status`
                ] === "Realizado"
              ) {
                arrPaidHotels.push(hotel.id);
              }
            });

            if (hotelRows.length === arrPaidHotels.length)
              allHotelsIsPaid = true;
          }

          if (product === "flight") {
            const flightRows = JSON.parse(contract.meta.flight_sections_rows);
            flightRows.forEach((flight) => {
              if (
                contract.meta[
                  `contract_finances_flight_${flight.id}_audit_status`
                ] === "Realizado"
              )
                if (
                  contract.meta[`flight_${flight.id}_type`] === "Milhas" &&
                  contract.meta[
                    `contract_finances_flight_miles_taxes_audit_status`
                  ] === "Realizado"
                ) {
                  arrPaidFlights.push(flight.id);
                } else {
                  arrPaidFlights.push(flight.id);
                }
            });

            if (flightRows.length === arrPaidFlights.length)
              allFlightsIsPaid = true;
          }

          if (product === "service") {
            const serviceRows = JSON.parse(contract.meta.service_rows);
            serviceRows.forEach((service) => {
              if (
                contract.meta[
                  `contract_finances_service_${service.id}_audit_status`
                ] === "Realizado"
              )
                arrPaidServices.push(service.id);
            });

            if (serviceRows.length === arrPaidServices.length)
              allServicesIsPaid = true;
          }
        });

        packageIsPaid = true;
        if (!contractedServices.includes("hotel")) allHotelsIsPaid = true;
        if (!contractedServices.includes("flight")) allFlightsIsPaid = true;
        if (!contractedServices.includes("service")) allServicesIsPaid = true;
      }

      this.isAllPaid =
        allHotelsIsPaid &&
        allFlightsIsPaid &&
        allServicesIsPaid &&
        packageIsPaid;
    },
  },
};
</script>

<style lang="sass" scoped>

.print
  position: relative
  cursor: pointer
  top: 5px
  font-size: 18px
  color: #999
</style>
